<template>
    <div>
        <div class="default_box" v-if="preLoad">
            <div class="default_header" v-if="title">
                <h1 v-html="title"></h1>
            </div>
            <div class="default_box_content">
                <div v-for="(item, index) in list" :key="index">
                    <article class="default_item bg_white">
                        <router-link :to="{name:'NewsGetNew',params: { id:item.id }}">
                            <b-row no-gutters>
                                <b-col md="4" class="default_item_image_box">
                                    <link v-if="item.image" rel="prefetch prerender" :href="item.image" />
                                    <img :src="(item.image) ? item.image : '/images/default_image.jpg'" class="img-fluid">
                                </b-col>
                                <b-col md="8" class="default_item_body">
                                    <h3 class="default_item_title" v-if="item.title">{{item.title}}</h3>
                                    <div class="default_item_text" v-html="$htmContext($cutText(item.text, limit))"></div>
                                    <div class="default_item_footer">
                                        <div class="default_item_footer_date" v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:MM')"></div>
                                        <div class="default_item_footer_comment">
                                            <span v-if="parseInt(item.comments_count) > 0" class="default_item_footer_com_count">{{item.comments_count}}</span>
                                            <font-awesome-icon :icon="['fa', 'comment']" class="default_item_footer_date_icon" />
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </article>
                    <AdsPlaginNew v-if="index !== 0 && index % 3 === 0" :direction="'horizontal'" />
                </div>
            </div>
            <div v-if="button_preloader">
                <button v-if="page < totalPages" class="load_next_button" @click="api_next()">Показать ещё</button>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
export default {
    name: "InterestingList",
    components: {},
    metaInfo() {
        return {
            title: this.title ? `${this.title} - ` : null,
            meta: [
                {name: 'description', itemprop: 'description', content: `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: this.title ? `${this.title} - ${this.$myCity.name}` : `Новости - ${this.$myCity.name}`},
                {property: 'og:description', content: `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            title: null,
            list: [],
            button_preloader: 'finish',
            page: 1,
            totalPages: null,
            limit: 120,
        }
    },
    methods: {
        api() {
            this.preLoad = null;
            this.list = [];
            this.page = 1;
            let url = this.$config.api_url + this.$route.params.city + '/Interesting.getList/' + this.$route.params.id;
            this.$http.get(url, {
                params: {
                    page: this.page,
                }
            }).then((response) => {
                if (!response.data.response.category) {
                    this.$router.push({ name: 'Home' });
                    this.$notify({
                        group: 'error',
                        type: 'error',
                        text: 'Такого раздела не существует',
                        title: 'Ошибка!',
                        duration: 5000,
                    });
                } else {
                    this.title = response.data.response.category.title;
                    this.list = this.list.concat(response.data.response.top).concat(response.data.response.list);
                    this.totalPages = response.data.response.link.total_pages;

                }
            }).finally(() => {
                this.preLoad = 'finish';

            })
        },
        api_next() {
            this.button_preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Interesting.getList/' + this.$route.params.id;
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.response.list);
            }).catch(error => {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: error,
                    title: 'Ошибка!',
                    duration: 5000,
                });
            }).finally(() => {
                this.button_preloader = 'finish';
            });
        },

    },
    mounted() {
        this.api();
    },
    watch: {
        '$route.params.id': function() {
            this.api();
        }
    },
}
</script>
<style scoped>
</style>